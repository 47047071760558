<template>
  <div>
    <div class="loading-overlay" :class="show ? 'is-active' : ''">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
    <div v-if="rendered">
      <!-- MODALS  -->

      <modal :show.sync="modalOrcamento" headerClasses="justify-content-center">
        <div class="row">
          <div class="col-12 text-center">
            <h5>
              Descritivo da proposta do cliente
              <span class="modalCliente">{{
                orcamentoSelecionado.cliente
              }}</span>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <span class="modalTituloDescritivo">Projeto: </span
            >{{ orcamentoSelecionado.proposta }}
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6">
            <span class="modalTituloDescritivo">ID Clickup </span
            ><fg-input
              @keyup="editado = true"
              placeholder="ID ClickUp"
              v-model="orcamentoSelecionado.id_clickup"
            ></fg-input>
          </div>
          <div class="col-6">
            <span class="modalTituloDescritivo">Valor Yourcode: </span
            ><fg-input
              @keyup="editado = true"
              placeholder="Valor Yourode"
              v-model="orcamentoSelecionado.valor_yc"
            ></fg-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <span class="modalTituloDescritivo">Valor da Avença: </span
            ><fg-input
              @keyup="editado = true"
              placeholder="Valor da Avença"
              v-model="orcamentoSelecionado.avenca"
            ></fg-input>
          </div>
          <div class="col-6">
            <span class="modalTituloDescritivo"
              >Valor enviado para o cliente:
            </span>
            <fg-input
              @keyup="editado = true"
              placeholder="Valor enviado para o cliente"
              v-model="orcamentoSelecionado.valor_cliente"
            ></fg-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <span class="modalTituloDescritivo">Horas avença: </span
            ><fg-input
              @keyup="editado = true"
              placeholder="Horas avença"
              v-model="orcamentoSelecionado.horas"
            ></fg-input>
          </div>
          <div class="col-6">
            <span class="modalTituloDescritivo">Valor para o grupo: </span>
            <fg-input
              @keyup="editado = true"
              placeholder="Valor para o grupo"
              v-model="orcamentoSelecionado.valor_grupo"
            ></fg-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <span class="modalTituloDescritivo">Descrição:</span>
            <textarea
              @keyup="editado = true"
              class="form-control padding-left"
              placeholder="Adicionar escrição"
              rows="3"
              v-model="orcamentoSelecionado.descricao"
            ></textarea>
          </div>
          <div
            class="col-6"
            v-if="
              orcamentoSelecionado.questionario &&
              orcamentoSelecionado.questionario.length > 20
            "
          >
            <span class="modalTituloDescritivo">Download Briefing: </span>
            <download-excel
              class="btn btn-danger"
              :data="orcamentoSelecionado.jsonDownload"
              :fields="json_fields"
              worksheet="My Worksheet"
              :name="orcamentoSelecionado.cliente + '.xls'"
            ></download-excel>
          </div>
        </div>
        <div class="row mt-3" v-if="editado">
          <div class="col-12 text-center">
            <p-button
              type="default"
              class="editarModal"
              link
              @click="gravar(orcamentoSelecionado)"
              ><i class="nc-icon nc-check-2"></i
            ></p-button>
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button type="default" link @click="downloadProposta()"
              >Download Proposta</p-button
            >
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" link @click="gerarLinkPublicoQuestionario()"
              >Gerar Link Questionário Cliente</p-button
            >
          </div>
        </template>
      </modal>
      <!--Stats cards-->

      <div class="row">
        <div
          class="col-lg-3 col-md-6 col-sm-6"
          v-for="(stats, i) in statsCards"
          :key="i"
        >
          <stats-card
            :type="stats.type"
            :icon="stats.icon"
            :small-title="stats.title"
            :title="stats.value"
          >
          </stats-card>
        </div>
        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
          <stats-card
            type="warning"
            icon="nc-icon nc-sound-wave"
            :small-title="'Progresso Objetivo ' + anoCorrente"
            :title="percentagemObjetivo + ' %'"
          >
          </stats-card>
        </div> -->
      </div>

      <div class="row mt-5">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-sm-3">
              <el-select
                class="select-danger"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-danger"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3">
              <p-button
                @click="adicionarRow()"
                slot="footer"
                type="warning"
                block
                class="mb-3"
                style="margin: 0 !important"
                >Adicionar Proposta</p-button
              >
            </div>
            <div class="col-sm-3">
              <div class="pull-right">
                <fg-input>
                  <el-select
                    v-model="filtro"
                    class="select-danger"
                    size="large"
                    placeholder="Filtrar"
                    @change="filtrar(filtro)"
                  >
                    <el-option
                      v-for="(option, i) in selects.estados_Adjudicacao_filtro"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="i"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="pull-right">
                <fg-input
                  class="input-sm"
                  placeholder="Pesquisa"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split"
                >
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table
                class="table-striped"
                :data="queriedData"
                border
                style="width: 100%"
              >
                <el-table-column min-width="150" label="Cliente">
                  <div slot-scope="props">
                    <fg-input>
                      <el-select
                        class="select-danger"
                        size="large"
                        placeholder="Selecionar"
                        v-model="props.row.cliente"
                      >
                        <el-option
                          v-for="(option, i) in selects.clientes"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="i"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </el-table-column>

                <el-table-column min-width="150" label="Projeto">
                  <div slot-scope="props">
                    <fg-input>
                      <el-select
                        class="select-danger"
                        size="large"
                        placeholder="Selecionar"
                        v-model="props.row.proposta"
                      >
                        <el-option
                          v-for="(option, i) in selects.projetos"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="i"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Origem">
                  <div slot-scope="props">
                    <fg-input>
                      <el-select
                        class="select-danger"
                        size="large"
                        placeholder="Selecionar"
                        v-model="props.row.origem"
                      >
                        <el-option
                          v-for="(option, i) in selects.empresas"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="i"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Outra">
                  <div slot-scope="props">
                    <fg-input>
                      <el-select
                        class="select-danger"
                        size="large"
                        placeholder="Selecionar"
                        v-model="props.row.outras"
                      >
                        <el-option
                          v-for="(option, i) in selects.empresas"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="i"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Valor YC">
                  <div slot-scope="props">
                    {{ numberWithCommas(props.row.valor_yc) }} €
                  </div>
                </el-table-column>
                <el-table-column min-width="150" label="Adjudicação">
                  <div slot-scope="props">
                    <fg-input>
                      <el-select
                        class="select-danger"
                        size="large"
                        placeholder="Selecionar"
                        v-model="props.row.estado_adjudicacao"
                      >
                        <el-option
                          v-for="option in selects.estados_Adjudicacao"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="option.label"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </el-table-column>
                <el-table-column
                  :min-width="150"
                  fixed="right"
                  class-name="td-actions text-center"
                  label="Ações"
                >
                  <template slot-scope="props">
                    <p-button
                      v-if="props.row.id != ''"
                      type="success"
                      size="sm"
                      icon
                      @click="gravar(props.row)"
                    >
                      <i class="nc-icon nc-check-2"></i>
                    </p-button>
                    <p-button
                      v-else
                      type="success"
                      size="sm"
                      icon
                      @click="adicionar(props.row)"
                    >
                      <i class="nc-icon nc-check-2"></i>
                    </p-button>
                    <p-button
                      style="background-color: #fccf71"
                      size="sm"
                      icon
                      @click="abrirModal(props.row)"
                    >
                      <i class="nc-icon nc-badge"></i>
                    </p-button>
                    <p-button
                      type="danger"
                      size="sm"
                      icon
                      @click="apagarOrcamento(props.row)"
                    >
                      <i class="nc-icon nc-simple-remove"></i>
                    </p-button>
                    <p-button
                      type="info"
                      size="sm"
                      icon
                      @click="onButtonClick(props.row.id)"
                    >
                      <i class="nc-icon nc-cloud-upload-94"></i>
                    </p-button>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept=".pps,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                      @change="onFileChanged"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6">
              <p-pagination
                class="pull-right"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import CircleChartCard from "src/components/UIComponents/Cards/CircleChartCard.vue";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { Badge, Modal } from "src/components/UIComponents";
import Loading from "src/components/Dashboard/Layout/LoadingMainPanel.vue";
import TaskList from "./Widgets/TaskList";
import api from "@/api/index";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import NotificationTemplate from "../Components/NotificationTemplate.vue";
import Swal from "sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import JsonExcel from "vue-json-excel";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.component("downloadExcel", JsonExcel);
const WorldMap = () => ({
  component: import("./../Maps/WorldMap.vue"),
  loading: Loading,
  delay: 200,
});

export default {
  components: {
    StatsCard,
    Modal,
    ChartCard,
    CircleChartCard,
    WorldMap,
    Badge,
    TaskList,
    PPagination,
    NotificationTemplate,
    VueJsonToCsv,
    JsonExcel,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      filtro: "",
      show: true,
      json_fields: {
        pergunta: "pergunta",
        resposta: "resposta",
      },
      editado: false,
      id: "",
      anoCorrente: "",
      percentagemObjetivo: 0,
      questionarios: [],
      projetos: [],
      modalOrcamento: false,
      type: ["", "info", "success", "warning", "danger"],
      selects: {
        estados_Adjudicacao: [
          {
            value: "1",
            label: "Adjudicado",
          },
          {
            value: "2",
            label: "Por adjudicar",
          },
          {
            value: "3",
            label: "Não adjudicado",
          },
        ],
        estados_Adjudicacao_filtro: [
          {
            value: "0",
            label: "Todos",
          },
          {
            value: "1",
            label: "Adjudicado",
          },
          {
            value: "2",
            label: "Por adjudicar",
          },
          {
            value: "3",
            label: "Não adjudicado",
          },
        ],
        clientes: [{ label: "Selecionar", value: "" }],
        empresas: [{ label: "Selecionar", value: "" }],
        projetos: [{ label: "Selecionar", value: "" }],
      },
      rendered: false,
      pagination: {
        perPage: 30,
        currentPage: 1,
        perPageOptions: [60, 100, 200, 500],
        total: 0,
      },
      orcamentoSelecionado: [],
      searchQuery: "",
      tableData: [],
      tableDataBU: [],
      propsToSearch: ["cliente"],
      statsCards: [
        {
          type: "warning",
          icon: "nc-icon nc-globe",
          title: "Valores enviados",
          value: "",
          footerText: "",
          footerIcon: "",
        },
        {
          type: "warning",
          icon: "nc-icon nc-single-copy-04",
          title: "Projetos",
          value: "",
          footerText: "",
          footerIcon: "",
        },
        {
          type: "warning",
          icon: "nc-icon nc-favourite-28",
          title: "Total Clientes",
          value: "",
          footerText: "",
          footerIcon: "",
        },
        {
          type: "warning",
          icon: "nc-icon nc-refresh-69",
          title: "Avenças",
          value: "100",
          footerText: "",
          footerIcon: "",
        },
        {
          type: "warning",
          icon: "nc-icon nc-ambulance",
          title: "Total avença / mês",
          value: "1000 €",
          footerText: "",
          footerIcon: "",
        },
        {
          type: "warning",
          icon: "nc-icon nc-money-coins",
          title: "Total valores adjudicados",
          value: "",
          footerText: "",
          footerIcon: "",
        },
      ],
    };
  },
  methods: {
    onButtonClick(id) {
      this.id = id;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      var _this = this;
      var file = e.target.files[0];
      var formato = file.type.replace(/(.*)\//g, "");
      console.log(formato);
      // console.log(e.target.files[0]);

      var nomeFicheiro = Date.now();
      var path =
        "https://yourcode-staging.com/api-yc-plataforma/documentos/" +
        this.user_id;

      const form_data = new FormData();
      form_data.append("file", file);
      form_data.append("nome", nomeFicheiro);
      form_data.append("pasta", this.id);
      await fetch("https://yourcode-staging.com/api-yc-plataforma/file.php", {
        method: "POST",
        body: form_data,
      })
        .then(function (response) {
          return response.text();
        })
        .then(async function (responseData) {
          let json = {
            id: _this.id,
            url: responseData,
          };
          let resultado = await api.atualizarUploadedFile(json);
          if (resultado.resultado == "OK") {
            _this.inicio();
            _this.notifyVue(
              "top",
              "right",
              "Ficheiro atualizado com sucesso!",
              "success"
            );
          } else {
            this.notifyVue("top", "right", "Ocorreu um erro!", "danger");
          }
        });
    },
    traverseAndFlatten(currentNode, target, flattenedKey) {
      for (var key in currentNode) {
        if (currentNode.hasOwnProperty(key)) {
          var newKey;
          if (flattenedKey === undefined) {
            newKey = key;
          } else {
            newKey = flattenedKey + "." + key;
          }

          var value = currentNode[key];
          if (typeof value === "object") {
            this.traverseAndFlatten(value, target, newKey);
          } else {
            target[newKey] = value;
          }
        }
      }
    },
    flatten(obj) {
      var flattenedObject = {};
      this.traverseAndFlatten(obj, flattenedObject);
      return flattenedObject;
    },
    gerarToken(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    downloadProposta() {
      if (this.orcamentoSelecionado.ficheiro_orcamento != null) {
        if (
          this.orcamentoSelecionado.ficheiro_orcamento.indexOf("https") > -1 &&
          this.orcamentoSelecionado.ficheiro_orcamento.length > 10
        ) {
          window.open(this.orcamentoSelecionado.ficheiro_orcamento, "_blank");
        } else {
          this.notifyVue(
            "top",
            "right",
            "Não existe ficheiro associado a este orçamento!",
            "danger"
          );
        }
      } else {
        this.notifyVue(
          "top",
          "right",
          "Não existe ficheiro associado a este orçamento!",
          "danger"
        );
      }
    },
    async gerarLinkPublicoQuestionario() {
      var proposta = this.orcamentoSelecionado.proposta;
      var idOrcamento = this.orcamentoSelecionado.id;
      var idProjeto = this.projetos.filter((x) => x.nome == proposta);
      if (idProjeto.length <= 0) {
        this.notifyVue(
          "top",
          "right",
          "Este projeto não existe na lista dos projetos, por favor corrija",
          "danger"
        );
      } else {
        idProjeto = idProjeto[0].id;
        var idBriefing = "";
        for (let i = 0; i < this.questionarios.length; i++) {
          var temp = [];
          temp = this.questionarios[i].id_projeto.split(",");
          if (temp.indexOf(idProjeto) > -1) {
            idBriefing = this.questionarios[i].id;
          }
        }
        if (idBriefing == "") {
          this.notifyVue(
            "top",
            "right",
            "Este projeto não tem um briefing associado",
            "danger"
          );
          return;
        }

        var token = this.gerarToken(50);
        var jsoncriarLink = {
          token: token,
          orcamento: idOrcamento,
          briefing: idBriefing,
        };

        let resultadoCriarLink = await api.criarBriefingCliente(jsoncriarLink);
        if (resultadoCriarLink.resultado == "OK") {
          navigator.clipboard.writeText(
            "https://projetos.yourcode.pt/#/briefings-cliente?token=" +
              token +
              "&questionario=" +
              idBriefing +
              "&projeto=" +
              idOrcamento
          );
          this.notifyVue(
            "top",
            "right",
            "Link gerado e copiado para o clipboard",
            "success"
          );
        }
      }
    },
    abrirModal(array) {
      this.orcamentoSelecionado = array;
      this.modalOrcamento = true;
    },
    apagarOrcamento(array) {
      var _this = this;
      const swalWithBootstrapButtons4 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons4
        .fire({
          title: "Atenção",
          text: `Tens a certeza que queres remover este item?`,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.value) {
            let res = await api.removerOrcamento(array);
            if (res.resultado == "OK") {
              _this.notifyVue(
                "top",
                "right",
                "Item removido com sucesso!",
                "success"
              );
              _this.inicio();
            } else {
              _this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
            }
          }
        });
    },
    adicionarRow() {
      this.tableData.unshift({
        id: "",
        cliente: "",
        proposta: "",
        descricao: "",
        valor_yc: "",
        avenca: "",
        horas: "",
        valor_cliente: "",
        valor_grupo: "",
        origem: "",
        outras: "",
        estado_adjudicacao: "",
        novo: true,
      });
    },
    filtrar(valor) {
      if (valor == "0") {
        this.tableData = this.tableDataBU;
        return;
      }
      this.tableData = this.tableDataBU.filter(
        (x) => x.estado_adjudicacao == valor
      );
    },
    async inicio() {
      let res = await api.orcamentos();
      res = Object.values(res);
      this.tableData = res.sort((a, b) => {
        return Number(a.id) > Number(b.id) ? -1 : 1;
      });
      this.tableDataBU = this.tableData;
      var today = new Date();

      var anoCorrente = today.getFullYear();
      var objetivo = 0;

      var jsonDataObj = {
        ano: anoCorrente,
      };
      let resultadoObjetivo = await api.listarObjetivo(jsonDataObj);
      resultadoObjetivo = Object.values(resultadoObjetivo);

      var objetivoAvencas = 0;
      var objetivoClientes = 0;
      var objetivoProjetos = 0;
      var objetivoValores_adjudicados = 0;

      if (resultadoObjetivo.length > 0) {
        for (let i = 0; i < resultadoObjetivo.length; i++) {
          if (resultadoObjetivo[i].ano == anoCorrente) {
            if (resultadoObjetivo[i].tipo == "avencas") {
              objetivoAvencas = Number(resultadoObjetivo[i].objetivo);
            }
            if (resultadoObjetivo[i].tipo == "valores_adjudicados") {
              objetivoValores_adjudicados = Number(
                resultadoObjetivo[i].objetivo
              );
            }
            if (resultadoObjetivo[i].tipo == "clientes") {
              objetivoClientes = Number(resultadoObjetivo[i].objetivo);
            }
            if (resultadoObjetivo[i].tipo == "projetos") {
              objetivoProjetos = Number(resultadoObjetivo[i].objetivo);
            }
          }
        }
      }

      this.anoCorrente = anoCorrente;

      this.selects.clientes = [{ label: "Selecionar", value: "" }];
      this.selects.empresas = [{ label: "Selecionar", value: "" }];
      this.selects.projetos = [{ label: "Selecionar", value: "" }];
      var valor_enviado = 0;
      var valor_adjudicado = 0;
      var projetos = res.length;
      var clientes = [];
      var avencas = 0;
      var valorAvencas = 0;
      var progressoObjetivo = 0;
      var avencasAnoCorrente = 0;
      var resultadoAdjudicado = res.filter((x) => x.estado_adjudicacao == "1");
      console.log(resultadoAdjudicado);
      for (let i = 0; i < res.length; i++) {
        if (res[i].questionario && res[i].questionario.length > 20) {
          var temp = JSON.parse(res[i].questionario);
          res[i].jsonDownload = [];
          for (let k = 0; k < temp[0].primeiroPasso.length; k++) {
            res[i].jsonDownload.push({
              pergunta: temp[0].primeiroPasso[k].pergunta,
              resposta: temp[0].primeiroPasso[k].resposta,
            });
          }
          for (let k = 0; k < temp[0].segundoPasso.length; k++) {
            res[i].jsonDownload.push({
              pergunta: temp[0].primeiroPasso[k].pergunta,
              resposta: temp[0].primeiroPasso[k].resposta,
            });
          }
        }

        clientes.push(res[i].cliente);
      }
      for (let i = 0; i < resultadoAdjudicado.length; i++) {
        if (resultadoAdjudicado[i].valor_yc) {
          valor_enviado += Number(resultadoAdjudicado[i].valor_yc);
        }
        if (resultadoAdjudicado[i].estado_adjudicacao == "1") {
          valor_adjudicado += Number(resultadoAdjudicado[i].valor_yc);
        }
        if (Number(resultadoAdjudicado[i].avenca) > 0) {
          valorAvencas += Number(resultadoAdjudicado[i].avenca);
          avencas++;
        }
        if (
          Number(resultadoAdjudicado[i].avenca) > 0 &&
          resultadoAdjudicado[i].dt_created.indexOf(anoCorrente) > -1
        ) {
          valorAvencas += Number(resultadoAdjudicado[i].avenca);
          avencasAnoCorrente++;
        }
      }

      progressoObjetivo = ((avencasAnoCorrente * 100) / objetivo).toFixed(2);
      this.percentagemObjetivo = progressoObjetivo;
      var clientesSet = [...new Set(clientes)];
      this.statsCards[0].value = this.numberWithCommas(valor_enviado) + " €";
      this.statsCards[1].value =
        this.numberWithCommas(projetos).toString() +
        " / " +
        this.numberWithCommas(objetivoProjetos).toString();
      this.statsCards[2].value =
        this.numberWithCommas(clientesSet.length).toString() +
        " / " +
        this.numberWithCommas(objetivoClientes).toString();
      this.statsCards[3].value =
        this.numberWithCommas(avencas).toString() +
        " / " +
        this.numberWithCommas(objetivoAvencas).toString();
      this.statsCards[4].value =
        this.numberWithCommas(valorAvencas).toString() + " €";
      this.statsCards[5].value =
        this.numberWithCommas(valor_adjudicado) +
        " €" +
        " / " +
        this.numberWithCommas(objetivoValores_adjudicados).toString() +
        " €";

      let resultadoEmpresas = await api.empresas();
      let resultadoClientes = await api.clientes();
      let resultadoProjetos = await api.projetos();

      resultadoEmpresas = Object.values(resultadoEmpresas);
      resultadoClientes = Object.values(resultadoClientes);
      resultadoProjetos = Object.values(resultadoProjetos);

      for (let i = 0; i < resultadoEmpresas.length; i++) {
        this.selects.empresas.push({
          label: resultadoEmpresas[i].nome,
          value: resultadoEmpresas[i].nome,
        });
      }
      for (let i = 0; i < resultadoClientes.length; i++) {
        this.selects.clientes.push({
          label: resultadoClientes[i].nome,
          value: resultadoClientes[i].nome,
        });
      }
      for (let i = 0; i < resultadoProjetos.length; i++) {
        this.selects.projetos.push({
          label: resultadoProjetos[i].nome,
          value: resultadoProjetos[i].nome,
        });
      }
      this.selects.projetos = this.selects.projetos.sort((a, b) => {
        return a.label == "Selecionar" ? -2 : a.label < b.label ? -1 : 1;
      });
      this.selects.clientes = this.selects.clientes.sort((a, b) => {
        return a.label == "Selecionar" ? -2 : a.label < b.label ? -1 : 1;
      });
      this.selects.empresas = this.selects.empresas.sort((a, b) => {
        return a.label == "Selecionar" ? -2 : a.label < b.label ? -1 : 1;
      });

      let resQuestionarios = await api.listarQuestionarios();
      this.questionarios = Object.values(resQuestionarios);

      let resProjetos = await api.projetos();
      this.projetos = Object.values(resProjetos);
      this.rendered = true;
      this.show = false;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    notifyVue(verticalAlign, horizontalAlign, texto, tipo) {
      this.$notify({
        title: texto,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: tipo,
        timeout: 2000,
      });
    },

    formatarTempoMili(tempo) {
      var minutes = moment.duration(tempo).minutes();
      var hours = Math.trunc(moment.duration(tempo).asHours());

      return hours + "." + minutes;
    },
    epoch(date) {
      return Date.parse(date);
    },
    async gravar(array) {
      let res = await api.atualizarProposta(array);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Proposta atualizada com sucesso!",
          "success"
        );
        this.inicio();
        this.editado = false;
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
    async adicionar(array) {
      let res = await api.adicionarProposta(array);
      console.log(res);
      if (res.resultado == "OK") {
        this.notifyVue(
          "top",
          "right",
          "Proposta adicionada com sucesso!",
          "success"
        );
        this.inicio();
      } else {
        this.notifyVue("top", "right", "Ocorreu um erro !", "danger");
      }
    },
  },
  async mounted() {
    this.inicio();
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("ã", "a")
            .replaceAll("á", "a")
            .replaceAll("à", "a")
            .replaceAll("é", "e")
            .replaceAll("è", "e")
            .replaceAll("ó", "o")
            .replaceAll("í", "i");
          if (
            rowValue.includes &&
            rowValue.includes(
              this.searchQuery
                .toString()
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("ã", "a")
                .replaceAll("á", "a")
                .replaceAll("à", "a")
                .replaceAll("é", "e")
                .replaceAll("è", "e")
                .replaceAll("ó", "o")
                .replaceAll("í", "i")
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
};
</script>
<style>
.el-select.select-default {
  border-radius: 0px !important;
}
.modalHeader {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.modalCliente {
  color: #ff4d1d;
  font-weight: bold;
  text-decoration: underline;
}

.modalTituloDescritivo {
  font-weight: bold;
  color: #ff4d1d;
  font-size: 16px;
}
.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.textoDesc {
  font-size: 18px;
}

.editarModal {
  width: 50%;
  background-color: #68cc86;
  color: #fff;
}
.el-select-dropdown.is-multiple.select-danger,
.el-select-dropdown__item.selected.select-danger {
  color: #fff !important;
  background-color: #ef8157 !important;
}
</style>
